import { feedItemsWithPlainTextContent, mediaFromFeedItems } from './utils';
import {
  ApiTypesV1GroupResponse,
  ApiTypesV1SiteMemberProfileResponse,
  GroupDTO,
  isGroupMember,
} from '@wix/social-groups-api';

import { GroupSEO } from './GroupSEO';
import { GroupSEOItemType, GroupSEOTabType } from './GroupSEOItemType';
import { ContentConverter } from '../../../common/ContentEditor/content/ContentConverter';
import { Tab } from '../../../common/controllers/group-url';
import { EFilterKeys } from 'Group/types/EFilterKeys';

interface IWithFeedProps {
  feedItems: any;
  url: string;
  cursor?: string;
  nextCursor?: string;
  prevCursor?: string;
}

// TODO: content(group description, feed items content) should be in plain text
export class SeoGroupBuilder {
  private itemType = GroupSEOItemType.GROUPS_PAGE;

  private readonly itemData: GroupSEO = {
    directUrl: '',
    group: undefined as any,
    tabType: undefined as any,
    tabs: undefined as any,
  };

  withGroup(group: ApiTypesV1GroupResponse) {
    if (this.itemData && this.itemData.group) {
      return this;
    }
    const groupDTO = new GroupDTO(group);
    const description = ContentConverter.parseText(groupDTO.getDescription());
    const logo = { height: 300, width: 300 };
    const logoUrl = groupDTO.getScaledLogo(logo.width, logo.height);

    const g = groupDTO.setDetails({ description, logoUrl, logo });
    this.itemData.group = g;
    return this;
  }

  withTabs(tabs: any) {
    this.itemData.tabs = tabs;
    return this;
  }

  private getItemData(): GroupSEO {
    const { feedItems, groupMembers, feed, ...itemData } = this.itemData;

    try {
      switch (this.itemData.tabType) {
        case GroupSEOTabType.FEED:
          (itemData as GroupSEO).feedItems = feedItems;
          (itemData as GroupSEO).feed = feed;
          break;

        case GroupSEOTabType.MEMBERS:
          (itemData as GroupSEO).groupMembers = groupMembers;
          break;

        case GroupSEOTabType.POST:
          const [post] = feedItems as any;
          itemData.post = post;
          itemData.postData = {
            content: ContentConverter.parseText(post?.entity?.body),
            images: itemData.imagesData,
            videos: itemData.videosData,
          };
          break;

        default:
      }
    } catch (e) {
      console.error('Error in [SeoGroupBuilder.getItemData]: ', e);
    }

    return itemData;
  }

  withFeed(feed: IWithFeedProps) {
    const media = mediaFromFeedItems(feed.feedItems);

    const currentUrl = feed.cursor
      ? `${feed.url}?${EFilterKeys.CURSOR}=${feed.cursor}`
      : feed.url;

    const prevUrl = feed.prevCursor
      ? `${feed.url}?${EFilterKeys.CURSOR}=${feed.prevCursor}`
      : undefined;

    const nextUrl = feed.nextCursor
      ? `${feed.url}?${EFilterKeys.CURSOR}=${feed.nextCursor}`
      : undefined;

    this.itemData.feedItems = feedItemsWithPlainTextContent(feed.feedItems);
    this.itemData.videosData = media.videosData;
    this.itemData.imagesData = media.imagesData;
    this.itemData.feed = {
      url: feed.url,
      currentUrl,
      prevUrl,
      nextUrl,
    };

    return this;
  }

  withMembers(members: ApiTypesV1SiteMemberProfileResponse[]) {
    this.itemData.groupMembers = members.filter(isGroupMember);
    return this;
  }

  forLocation(url: string, activeTab: Tab, feedItemId?: string) {
    this.itemData.tabType = this.getTabType(activeTab, feedItemId);
    this.itemData.directUrl = url;
    return this;
  }

  public build(): {
    itemType: string;
    itemData: GroupSEO;
  } {
    return {
      itemType: this.itemType,
      itemData: this.getItemData(),
    };
  }

  private getTabType(activeTab: Tab, feedItemId?: string): GroupSEOTabType {
    if (feedItemId) {
      this.itemType = GroupSEOItemType.GROUPS_POST;
      return GroupSEOTabType.POST;
    }

    switch (activeTab) {
      case Tab.ABOUT:
        return GroupSEOTabType.ABOUT;
      case Tab.MEMBERS:
        return GroupSEOTabType.MEMBERS;
      case Tab.MEDIA:
        return GroupSEOTabType.MEDIA;
      default:
        return GroupSEOTabType.FEED;
    }
  }
}
