import {
  ActivityApi,
  ApiTypesV1GroupResponse,
  canSeeGroup,
} from '@wix/social-groups-api';
import { Controller } from '../Controller';
import { ActivityControllerProps } from './ActivityControllerProps';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { BaseControllerContext } from 'common/controllers';

export class ActivityController extends Controller<ActivityControllerProps> {
  private api: ActivityApi;

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.api = new ActivityApi(new BaseControllerContext(controllerContext));
    this.onUserLogin(() => {
      this.api = new ActivityApi(new BaseControllerContext(controllerContext));
    });
  }

  setProps(props: Partial<ActivityControllerProps>) {
    this.controllerConfig.setProps(props);
  }

  async pageReady(): Promise<void> {
    this.setProps({
      activities: undefined,
      activityActions: {
        getActivity: this.getActivity,
      },
    });
  }

  private readonly getActivity = async (group: ApiTypesV1GroupResponse) => {
    if (!canSeeGroup(group)) {
      return;
    }
    let newPosts = 0;
    try {
      const { totalPosts } = await this.api.getActivities(group.groupId!);
      newPosts = totalPosts || 0;
    } catch (e: any) {
      console.error('Error in [ActivityController.getActivity]', e);
      this.flowAPI.errorMonitor.captureException(e);
    }
    this.setProps({ activities: { newPosts } });
  };
}
