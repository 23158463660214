import { Controller } from '../Controller';
import {
  I$WWrapper,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { ApiTypesV1GroupResponse, isMember } from '@wix/social-groups-api';
import {
  CommentsControllerApi,
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import { ICommentsProps } from './ICommentsProps';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import { isMemberCommentAction } from './isMemberCommentAction';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { RequestToJoin } from 'Group/controllers/pubSub/PayoloadTypes';
import { UserRequestResponse } from 'common/context/user/IUserContext';

const COMMENTS_TRANSLATIONS = 'comments_translations';
export class CommentsController
  extends Controller<ICommentsProps>
  implements PubSubObserver
{
  private pendingJoinRequest!: {
    resolve: (value?: unknown) => void;
    // reject: (reason?: any) => void;
  };

  private commentsApi!: CommentsControllerApi;

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypesV1GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.setSubscriptions();
  }

  pageReady(
    $w: I$WWrapper | undefined,
    wixAPI: IWixAPI | undefined,
  ): Promise<any> {
    if (!this.isSSR()) {
      this.initComments().catch((e: any) => {
        console.log('Error in [CommentsController.pageReady:initComments]', e);
        this.flowAPI.errorMonitor.captureException(e);
      });
    }
    this.setState({
      commentsTranslations: this.getCommentsTranslations(),
    });
    return Promise.resolve(undefined);
  }

  private async initComments() {
    // TODO: [YO] comments experiment

    const subscription = (next: any) => (action: any) => {
      if (!this.getTranslations()) {
        const commentsTranslations = action.payload;
        if (
          action.type === 'FETCH_TRANSLATIONS_SUCCESS' &&
          commentsTranslations
        ) {
          this.saveTranslations(commentsTranslations);
          this.setState({ commentsTranslations });
        }
      }
      next(action);
    };

    const commentsInteractions = {
      appDefinitionId: this.controllerConfig.appParams.appDefinitionId,
      actionPreconditions: {
        isPreconditionRequiredForAction: (action: any) => {
          if (isMemberCommentAction(action)) {
            return !isMember(this.group);
          }
          return false;
        },
        preconditionCallback: async (action: any) => {
          await new Promise((resolve, reject) => {
            this.publish<RequestToJoin>(PubSubEventTypes.REQUEST_TO_JOIN, {
              groupId: this.group.groupId!,
            });
            /**
             * Implementation note:
             *
             * Don't reject this.pendingJoinRequest even in refuse to join
             * because comments doesn't handle it and comment will stuck in publishing state.
             *
             * They expect successfully resolved promise and
             * After they run preconditionCallback they call again the isPreconditionRequiredForAction
             * If it returns true - they decide that custom login was canceled.
             * If it returns false - they refetch comment permissions and make the decision depending on new permissions
             */
            this.pendingJoinRequest = {
              resolve: () => {
                this.pendingJoinRequest = null as any;
                resolve(undefined);
              },
            };
          });
        },
      },
      subscribe: [subscription],
      httpClient: this.getHTTPClient(),
    };
    this.commentsApi = await initializeCommentsController(
      this.controllerConfig,
      commentsInteractions,
    );

    this.commentsApi.watch.pagination.onChange((paginationState) => {
      this.publishCommentsChanged(paginationState);
    });
  }

  setSubscriptions() {
    this.subscribe<UserRequestResponse>(
      PubSubEventTypes.JOIN_GROUP,
      ({ data }) => this.maybeResolvePendingJoinRequest(data.group.id!),
    );
    this.subscribe<RequestToJoin>(PubSubEventTypes.REFUSE_TO_JOIN, ({ data }) =>
      this.maybeResolvePendingJoinRequest(data.groupId),
    );
  }
  removeSubscriptions() {}

  private maybeResolvePendingJoinRequest(groupId: string) {
    if (this.pendingJoinRequest && groupId === this.group.groupId) {
      this.pendingJoinRequest.resolve();
    }
  }

  private publishCommentsChanged(action: PaginationState) {
    try {
      this.controllerConfig.platformAPIs.pubSub.publish(
        PubSubEventTypes.COMMENTS_CHANGED,
        action,
        false,
      );
    } catch (e: any) {
      console.log('Error in [CommentsController.publishCommentsChanged]', e);
      this.flowAPI.errorMonitor.captureException(e);
    }
  }

  private saveTranslations(commentsTranslations: object): void {
    this.getMemoryStorage().setItem(
      COMMENTS_TRANSLATIONS,
      JSON.stringify(commentsTranslations),
    );
  }

  private getCommentsTranslations() {
    try {
      const commentsTranslations = this.getMemoryStorage().getItem(
        COMMENTS_TRANSLATIONS,
      );
      return JSON.parse(commentsTranslations!);
    } catch (e: any) {
      console.log('Error in [CommentsController.getCommentsTranslations]', e);
      this.flowAPI.errorMonitor.captureException(e);
    }
  }
}
