import { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  ApiTypesV1GroupResponse,
  SocialContentService,
} from '@wix/social-groups-api';

import { Controller } from '../Controller';
import {
  IMediaControllerState,
  MEDIA_SCOPE,
  MediaControllerProps,
} from './MediaControllerProps';
import { flatten } from '../../utils/utils';
import { ProGalleryConverter } from 'common/ContentEditor/plugins/pro-gallery/ProGalleryConverter';
import { EFilterKeys } from '../../types/EFilterKeys';
import { BaseControllerContext } from 'common/controllers';

export class MediaController extends Controller<MediaControllerProps> {
  repository!: SocialContentService;

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypesV1GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.onUserLogin(this.handleUserLogin);

    this.init();
  }

  handleUserLogin = () => {
    return this.init();
  };

  pageReady() {
    return Promise.resolve();
  }

  protected async init() {
    const groupId = await this.getGroupId();

    this.repository = new SocialContentService(
      groupId!,
      new BaseControllerContext(this.controllerContext),
    );
  }

  _setProps(props: Partial<IMediaControllerState>) {
    return this.controllerConfig.setProps({
      [MEDIA_SCOPE]: {
        fetch: this.fetch,
        ...props,
      },
    });
  }

  async getInitialProps(): Promise<MediaControllerProps> {
    return {
      [MEDIA_SCOPE]: {
        fetch: this.fetch,
      },
    };
  }

  protected fetch = async () => {
    const { feedItems, total } = await this.repository.feed.search({
      filter: {
        [EFilterKeys.ATTACHMENTS_TYPE]: {
          $in: ['IMAGE', 'VIDEO'],
        },
      },
    });

    const items = feedItems!.map((fi) =>
      ProGalleryConverter.convertContentEntity(fi.entity as any, fi.createdBy!),
    );

    this._setProps({ items: flatten(items), total: Number(total) });
  };
}
