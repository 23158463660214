import * as IFeedTypes from '@wix/ambassador-feed-v1-feed-item/types';

import uuid from 'uuid/v4';

import { serializable, object } from 'serializr';

import { IAttachment } from '../../../types/IFeedItem';

class PixelDimensions implements IFeedTypes.PixelDimensions {
  @serializable
  width!: number;

  @serializable
  height!: number;
}

export class Attachment implements IAttachment {
  @serializable
  id: string;

  @serializable
  mimeType: string;

  @serializable
  uri: string;

  @serializable
  mediaType: IFeedTypes.MediaType;

  @serializable(object(PixelDimensions))
  pixelDimensions: IFeedTypes.PixelDimensions;

  constructor(attachment: IFeedTypes.Resource) {
    this.id = attachment.id || uuid();
    this.uri = attachment.uri!;
    this.mediaType = attachment.mediaType!;
    this.mimeType = attachment.mimeType!;
    this.pixelDimensions = attachment.pixelDimensions || {};
  }
}
