import { EFilterKeys } from 'Group/types/EFilterKeys';
import { Filters } from 'Group/types/FeedControllerProps';

export function hasFilters(filters?: Partial<Filters>) {
  if (!filters) {
    return false;
  }

  return Object.keys(filters).some(
    (key) => key !== EFilterKeys.CURSOR && !!filters[key as EFilterKeys],
  );
}
