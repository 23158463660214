import { serializable } from 'serializr';

import {
  ContentType,
  SocialIdentity as SocialIdentityType,
  Text,
} from '@wix/ambassador-feed-v1-feed-item/types';

export class SocialIdentity implements SocialIdentityType {
  @serializable
  identityId!: string;

  @serializable
  identityType: any;
}

export class SocialText implements Text {
  @serializable
  contentType!: ContentType;

  @serializable
  content!: string;
}
