import { IProGalleryItem } from '../../../../common/ContentEditor/plugins/pro-gallery/ProGalleryItem';

export const MEDIA_SCOPE = 'mediaContext';

export interface IMediaControllerState {
  items: IProGalleryItem[];
  total: number;
  fetch(): Promise<void>;
}

export interface MediaControllerProps {
  [MEDIA_SCOPE]: Partial<IMediaControllerState>;
}
