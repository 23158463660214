import { IJoinGroupRequestApi, RequestState } from '@wix/social-groups-api';

import { QueryJoinGroupRequestsResponse } from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { IPendingMember } from './IPendingMember';

export enum JoinGroupRequestType {
  queryPendingJoinRequests = 'queryPendingJoinRequests',
  approveJoinGroupRequests = 'approveJoinGroupRequests',
  rejectJoinGroupRequests = 'rejectJoinGroupRequests',
}

export interface PendingMembers {
  [id: string]: IPendingMember;
}

export interface IJoinGroupRequests {
  joinGroupRequestsActions: IJoinGroupRequestApi;
  joinGroupRequestsResponse:
    | (Pick<QueryJoinGroupRequestsResponse, 'metadata'> & {
        pendingMembers?: PendingMembers;
      })
    | null;
  joinGroupRequestRequests: {
    type: JoinGroupRequestType;
    status?: RequestState;
    siteMemberIds?: string[];
  } | null;
}
