import { Controller } from '../Controller';
import { NotificationChannel } from '@wix/ambassador-social-groups-v1-notification-settings/types';
import {
  GroupNotificationSettings,
  NotificationSettings,
} from './NotificationsControllerProps';

const { WEB, MOBILE, EMAIL } = NotificationChannel;
const CHANNELS = [EMAIL, MOBILE, WEB];

export class NotificationsController extends Controller<NotificationSettings> {
  setProps(props: Partial<NotificationSettings>) {
    this.controllerConfig.setProps(props);
  }
  pageReady(): Promise<any> {
    this.setProps({
      notificationActions: {
        getNotificationSettings: this.getNotificationSettings,
        setNotificationSettings: this.setNotificationSettings,
      },
      notificationSettings: undefined,
    });
    return Promise.resolve();
  }

  private readonly setNotificationSettings = async (
    settings: GroupNotificationSettings,
  ) => {
    try {
      const groupId = await this.getGroupId();
      await Promise.all(
        Object.keys(settings)
          .filter((channel) => (settings as any)[channel])
          .map((channel) => {
            return this._api
              .getNotificationsApi()
              .updateNotificationSettings(
                groupId!,
                (NotificationChannel as any)[channel],
                (settings as any)[channel],
              );
          }),
      );

      return this.getNotificationSettings();
    } catch (e: any) {
      console.error(
        'Error in [NotificationsController.setNotificationSettings]: ',
        e,
      );
      this.flowAPI.errorMonitor.captureException(e);
    }
  };

  private readonly getNotificationSettings = async () => {
    this.setProps({
      notificationSettings: null as any,
    });
    const initialNotificationSettings: GroupNotificationSettings = {
      [EMAIL as any]: undefined,
      [MOBILE as any]: undefined,
      [WEB as any]: undefined,
    } as any;
    const groupId = await this.getGroupId();

    await Promise.all(
      CHANNELS.map((channel) => {
        return this._api
          .getNotificationsApi()
          .getNotificationSettingsMap(groupId!, channel)
          .then((settings) => {
            initialNotificationSettings[channel] = settings!;
          })
          .catch((e) => {
            console.error(
              'Error in [NotificationsController.getNotificationSettings]: ',
              e,
            );
            this.flowAPI.errorMonitor.captureException(e);
          });
      }),
    );

    this.setProps({
      notificationSettings: initialNotificationSettings,
    });
  };
}
