import { dsl } from '@wix/yoshi-serverless/wrap';

export const createItem = dsl({
          functionName: 'createItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const updateItem = dsl({
          functionName: 'updateItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const deleteItem = dsl({
          functionName: 'deleteItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const getFeedItem = dsl({
          functionName: 'getFeedItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const searchFeed = dsl({
          functionName: 'searchFeed',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const addReaction = dsl({
          functionName: 'addReaction',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const removeReaction = dsl({
          functionName: 'removeReaction',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const subscribeToItem = dsl({
          functionName: 'subscribeToItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const unsubscribeFromItem = dsl({
          functionName: 'unsubscribeFromItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const pinItem = dsl({
          functionName: 'pinItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const unpinItem = dsl({
          functionName: 'unpinItem',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });

export const fetchFeed = dsl({
          functionName: 'fetchFeed',
          fileName: 'components/FeedWidget/api/group-feed.api',
        });